const Success = (props) => {


    return(
        <div>
            <h1>Thanks for your order!</h1>
    <p>
      We appreciate your business!
      If you have any questions, please email
    </p>
      <a style={{ color: "white"}} href="musclememoryofficial@yahoo.com">musclememoryofficial@yahoo.com</a>
        </div>
    )
}
export default Success